"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/web.dom.iterable");

var _sysLanguage = _interopRequireDefault(require("@/api/sysLanguage"));

var state = {
  sysLangs: []
};
var mutations = {
  getSysLangs: function getSysLangs(state, langs) {
    if (!state.sysLangs.length) {
      _sysLanguage.default.list().then(function (res) {
        state.sysLangs = res.content;
        state.sysLangs.forEach(function (obj) {
          obj.canSelect = true;
        });

        if (langs.length) {
          langs.forEach(function (langId) {
            state.sysLangs.find(function (item) {
              return item.id == langId;
            }).canSelect = false;
          });
        }
      });
    } else {
      state.sysLangs.forEach(function (obj) {
        obj.canSelect = true;
      });

      if (langs.length) {
        langs.forEach(function (langId) {
          state.sysLangs.find(function (item) {
            return item.id == langId;
          }).canSelect = false;
        });
      }
    }
  },
  removeSysLangs: function removeSysLangs(state, langId) {
    state.sysLangs.find(function (item) {
      return item.id == langId;
    }).canSelect = false;
  },
  addSysLang: function addSysLang(state, langId) {
    state.sysLangs.find(function (item) {
      return item.id == langId;
    }).canSelect = true;
  }
};
var getters = {
  sysLangsList: function sysLangsList(state) {
    return state.sysLangs.filter(function (lang) {
      return lang.canSelect;
    });
  },
  allLangsList: function allLangsList(state) {
    return state.sysLangs;
  },
  sysLangName: function sysLangName(state, langId) {
    return function (langId) {
      return (state.sysLangs.find(function (item) {
        return item.id == langId;
      }) || {}).name;
    };
  }
};
var _default = {
  state: state,
  mutations: mutations,
  getters: getters
};
exports.default = _default;