"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _index = _interopRequireDefault(require("../layout/index"));

_vue.default.use(_vueRouter.default);

var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/artview',
  meta: {
    title: '文章',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/wechat/article/artview'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: _index.default,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}, {
  path: '/yshop/materia/index',
  name: "images",
  meta: {
    title: '上传图片',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/components/editorMaterial/index'], resolve);
  },
  hidden: true
}];
exports.constantRouterMap = constantRouterMap;

var _default = new _vueRouter.default({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});

exports.default = _default;