"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.loadView = exports.filterAsyncRouter = void 0;

var _routers = require("@/router/routers");

var _index = _interopRequireDefault(require("@/layout/index"));

var permission = {
  state: {
    routers: _routers.constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = _routers.constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    }
  }
};

var filterAsyncRouter = function filterAsyncRouter(routers) {
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = _index.default;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }

    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children);
    }

    return true;
  });
};

exports.filterAsyncRouter = filterAsyncRouter;

var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

exports.loadView = loadView;
var _default = permission;
exports.default = _default;