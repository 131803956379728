"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/core-js/modules/es6.array.iterator.js");

require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/core-js/modules/es6.promise.js");

require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/core-js/modules/es6.object.assign.js");

require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _mavonEditor = _interopRequireDefault(require("mavon-editor"));

require("mavon-editor/dist/css/index.css");

var _Dict = _interopRequireDefault(require("./components/Dict"));

var _Permission = _interopRequireDefault(require("./components/Permission"));

require("./assets/styles/element-variables.scss");

require("./assets/styles/index.scss");

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));

require("highlight.js/styles/atom-one-dark.css");

require("@/assets/font_icon/iconfont.css");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _routers = _interopRequireDefault(require("./router/routers"));

require("./assets/icons");

require("./router/index");

//
// 数据字典
// 权限指令
// 初始化css
// global css
// 引入vue-quill-editor样式
// 代码高亮
// icon
// permission control
_vue.default.use(_vueHighlightjs.default);

_vue.default.use(_mavonEditor.default);

_vue.default.use(_Permission.default);

_vue.default.use(_Dict.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size

});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});