"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.del = del;
exports.edit = edit;
exports.list = list;
exports.timezoneList = timezoneList;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

function add(data) {
  return (0, _request.default)({
    url: 'api/sysLanguage',
    method: 'post',
    data: data
  });
}

function del(ids) {
  return (0, _request.default)({
    url: 'api/sysLanguage/',
    method: 'delete',
    data: ids
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'api/sysLanguage',
    method: 'put',
    data: data
  });
}

function list(data) {
  return (0, _request.default)({
    url: 'api/sysLanguage',
    method: 'get',
    data: data
  });
}

function timezoneList(langId) {
  return (0, _request.default)({
    url: 'api/sysLanguage/timezoneList?langId=' + langId,
    method: 'get'
  });
}

var _default = {
  add: add,
  edit: edit,
  del: del,
  list: list,
  timezoneList: timezoneList
};
exports.default = _default;