"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/wetalkline_web_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.removeToken = removeToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _settings = _interopRequireDefault(require("@/settings"));

var TokenKey = _settings.default.TokenKey;

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(TokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}